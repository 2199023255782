//
//      IF SLIDER
//


[data-init-slick] {
   
    display: block;
    z-index: inherit; // slick slider will handle z-index

    //slick fuoc fix
    max-height: $items-slider-fouc-height;
    overflow: hidden;
    opacity: 0;
    transition: opacity $default-transition-speed;

    // drop fouc if slick is initialized
    &.slick-initialized  {
        max-height: inherit;
        overflow: visible;
        opacity: 1;
    }
    

    [data-s-amount-item] {
    // because all items are in one line, margin on the bottom make no sense
        margin-bottom: 0;
    }

    // overrule border left behaviour
    @if $items-have-border-dividers == true {
        .slick-slide {
            border-left-color: transparent !important;
        }
        .slick-active ~ .slick-active{
            border-left: $item-border !important;
        }
    }

    // if amount on small == 1, we can hide the border all together. This also prevents content grow flickers
    @include breakpoint(small only) {
        [data-s-amount-item] {
            border-left-color: transparent;
        }
    }
}