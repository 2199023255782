
/* IMAGE - GLOBALS
--------------------------------------------------------------------------------------------*/

// render defaults within mixin to avoid sass error of not calling a selector
@mixin defaults() {
    position: relative;
}
@include defaults();

.ac_image {
    @include breakpoint(large) {
        display: none;
    }
}

