/* MIXIN - BOTTOM ITEMS NO BORDER 
----------------------------------------------------------------------------------------------------

    Removes border bottom from last items in a grid

*/


@mixin bottom-items-no-border($pattern) {
    
    //
    //      S1 versions
    //

    @if($pattern == 'S1-MP2'){ 
        &[data-s-amount="2"] {
            [data-s-amount-item] { 
                @include breakpoint(small only) {
                    @include last(1) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(medium-portrait) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S1-MP2-L3'){ 
        &[data-s-amount="3"] {
            [data-s-amount-item] { 
                @include breakpoint(small only) {
                    @include last(1) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(medium-portrait) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(medium) {
                    @include last(3) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S1-MP2-L4'){ 
        &[data-s-amount="4"] {
            [data-s-amount-item] { 
                @include breakpoint(small only) {
                    @include last(1) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(medium-portrait) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(large) {
                    @include last(4) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }


    @if($pattern == 'S1-MP2-L5'){ 
        &[data-s-amount="5"] {
            [data-s-amount-item] { 
                @include breakpoint(small only) {
                    @include last(1) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(medium-portrait) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(large) {
                    @include last(5) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }

    //
    //      S2 versions
    //

    @if($pattern == 'S2'){ 
        &[data-s-amount="2"] {
            [data-s-amount-item] { 
                @include last(2) {
                    [data-border-bottom] {
                        border-bottom-color: transparent;
                    }
                }
            }
        }
    }

    @if($pattern == 'S2-M3' or $pattern == 'S2-MP2-L3'){ 
        &[data-s-amount="3"] {
            [data-s-amount-item] { 
                @include breakpoint(medium-portrait down) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(medium) {
                    @include last(3) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S2-L4' or $pattern == 'S2-MP2-L4'){ 
        &[data-s-amount="4"] {
            [data-s-amount-item] { 
                @include breakpoint(medium down) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(large) {
                    @include last(4) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S2-L5' or $pattern == 'S2-MP2-L5'){ 
        &[data-s-amount="5"] {
            [data-s-amount-item] { 
                @include breakpoint(medium down) {
                    @include last(2) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
                @include breakpoint(large) {
                    @include last(5) {
                        [data-border-bottom] {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }
    }

}