/* GENERICS - PAGINATION
--------------------------------------------------------------------------------------------*/
.ac_pagination { 
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 $default-padding / 2;
    
    a, span {
        padding: 10px 15px;
        border: $default-border;
        border-right: none;

        &:first-child {
            border-top-left-radius: $default-border-radius;
            border-bottom-left-radius: $default-border-radius;
        }
        &:last-child {
            border-right: $default-border;
            border-top-right-radius: $default-border-radius;
            border-bottom-right-radius: $default-border-radius;
        }
    }

    // is current page
    span {
        background: $brand-primary-darker;
        color: $brand-white;
    }
}

