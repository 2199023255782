
/* BACKDROP 
--------------------------------------------------------------------------------------------*/

.ac_backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}
.ac_backdrop_image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.ac_backdrop_image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 1;
}

.ac_backdrop_video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
    }
}