
//
//      ITEM
//
.ac_item {
    display: flex;
    position: relative;
    // flex-direction: column;

    @include breakpoint(small only) {
        @if $items-on-small-two-items-a-row == true and $item-list-style-on-small == false {
            width: percentage(1/2);
            max-width: percentage(1/2);
        } @else {
            width: 100%;
            max-width: 100%;
        }

    }

    @if $item-margin-bottom != 0 {
        margin-bottom: $item-margin-bottom;
    }

    @if $items-have-padding == true {
        padding: 0 $default-padding;
    } @else {
        padding: 0;
    }

    @if $items-have-border-dividers == true {
        @if $items-have-padding == true {
            padding: 0 $default-padding;
        }
        border-left: $item-border;
    } @else {
        @if $items-have-padding == true {
            padding: 0 $default-padding / 2;
        }
    }

    @if $items-first-item-full-width == true {
        &:nth-child(1) {
            width: 100%;
            max-width: 100%;
            .ac_item_image-container {
                @if $item-list-style-on-small == true {
                    @include breakpoint(medium) {
                        height: $item-image-height * $items-first-item-full-width-height-multiplier;   
                    }
                } @else {
                    height: $item-image-height * $items-first-item-full-width-height-multiplier;   
                }
            }
        }
    }
}

.ac_item_container {
    min-height: 100%;
    width: 100%;
    position: relative;
    transition: all $default-transition-speed;
    @if $item-shadow-enabled == true {
        box-shadow: $default-box-shadow;
    }
    @if $items-have-border-below == true {
        border-bottom: $item-border;
    }
    @if $item-background != none or $item-background != transparent {
        background: $item-background;
    }
    @if $items-border-radius != 0 {
        border-radius: $items-border-radius;
    }
}
