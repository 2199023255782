
/* AC_S_CTA - GLOBALS
--------------------------------------------------------------------------------------------*/
.ac_cta_container {
    max-width: $ac-cta-max-width;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 $default-padding;

    @if $ac-cta-reversed-content == true {
        flex-direction: row-reverse;
    }

    p {
        line-height: 1.5;
    }
}   

.ac_cta_column {
    width: 100%;

    @include breakpoint(medium) { 
        @if $ac-cta-elements-no-margin == true {
            h2,h3,h4,p,.button {
                margin-bottom: 0;
            }
        }
        .button {
            margin-right: $default-padding / 2;
            margin-bottom: 0px;
        }
    }

    img {

        @if $ac-img-border-bottom == true {
            border-bottom: $ac-img-border; // fallback
        }

        max-width: 220px; // was 100%;
    }
}   


