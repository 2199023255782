
/* SERVICES - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="services"] {

    // items
    $items-slider-fouc-height: 200px; // what needs to be the flash-of-unwanted-content height before slider has laoded.

    $items-amount: 0; // 0 counts for undefined, if assigned the code will be more optimised
    $items-content-over-image: false;
    $items-have-padding: true;
    $items-have-border-dividers: false;
    $items-have-border-below: false;
    $items-hide-border-below-on-last-row: false;
    $items-border-radius: 0; 
    $items-first-item-full-width: false;
    $items-first-item-full-width-height-multiplier: 1.5;
    $items-on-small-two-items-a-row: false;
    $item-background: none;
    $item-background-hover: none;
    
    // columns
    $items-columns-enabled: false;
    $items-columns-breakpoint: 800px; 
    $items-columns-height: 400px; 
    $items-columns-hide-last-one-below-breakpoint: false;
    $items-columns-icon-image-combination: true;

    // item
    $item-margin-bottom: 0; // 1.4em
    $item-border: $default-border; // defines the appearance, not the display
    $item-shadow-enabled: false;


    // image
    $item-image-container-enabled: true;
    $item-image-enabled: true;
    $item-image-height: 160px;
    $item-image-initial-fade: 1;
    $item-image-hover-fade: .8;
    $item-image-gradient-before: true; // when item-content-over-image
    $item-image-gradient-color: $default-background-dark;
    $item-image-gradient-before-position: 'bottom'; // top or bottom
    $item-image-background-color: $default-background-dark;
    $item-image-background-color-hover: $default-background-dark;
    $item-image-border-radius: $default-border-radius; 


    // content
    $item-content-padding: 0; // $default-padding; 
    $item-content-background: none;
    $item-content-background-hover: none;
    $item-content-align: 'lb'; // lt, lc, lb, ct, cc, cb, rt, rc, rb ||  when item-content-over-image
    $item-content-copy-padding: $default-padding 0 ; 
    $item-content-copy-align: 'left'; // left or center

    $item-content-icon-enabled: false;
    $item-content-icon-centered: true;
    $item-content-icon-color: rgba($default-icon-color-light, 1);
    $item-content-icon-hover-color: rgba($default-icon-color-light, .4);
    $item-content-icon-size: 80px;
    $item-content-icon-padding: 10px;
    $item-content-icon-margin: 0 0 20px 0;
    $item-content-icon-background: $default-background-dark;
    $item-content-icon-background-hover: rgba($default-background-dark, .6);
    $item-content-icon-round: true;
    
    $item-content-title-enabled: true; 
    $item-content-title-color: $default-text-color;
    $item-content-title-hover-color: rgba($default-text-color, .6);

    $item-content-copy-above-enabled: false;    
    $item-content-copy-above-color: rgba($default-text-color, .6);
    $item-content-copy-above-hover-color: rgba($default-text-color, .4);

    $item-content-copy-below-enabled: false;    
    $item-content-copy-below-color: rgba($default-text-color, .6);
    $item-content-copy-below-hover-color: rgba($default-text-color, .4);

    // icon
    $item-show-icon: true;
    $item-icon-color: rgba($default-icon-color, .6);
    $item-icon-hover-color: rgba($default-icon-color-light, .4);
    $item-icon-size: 1.6em;
    $item-icon-position: 'rb'; // lt, lb, rt, rb
    $item-icon-inset-left-right: 0; // $default-padding; 
    $item-icon-inset-top-bottom: $default-padding; 
    

    // list style on small | image left and content right | all background will be reset | content icon is set absolute
    $item-list-style-on-small: false; 
    $item-list-style-image-width: 100px;
    $item-list-style-content-icon-size: 60px; 
    $item-list-style-content-icon-padding: 14px; 
    $item-list-style-content-icon-background: $default-background-light;
    $item-list-style-content-icon-color: $brand-primary; 
    $item-list-style-content-title-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-above-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-below-color: rgba($default-text-color, 1);


    @import '../ac_s__items-globals/__item_globals';
    

    /*--------------------------------------------------------------------------------------------*/

    box-shadow: inset 0 4px 10px -10px rgba($brand-tertiary, 1), inset 0 -4px 10px -10px rgba($brand-tertiary, 1);
    .ac_container{
        margin: 0 auto!important;
    }

    .ac_item{
        &:before{
            content: '';
            background: linear-gradient(0deg, $brand-secondary, $brand-primary)!important;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            height: 100%;
            width: 100%;
        }
        &:after{
            content: '';
            background: $brand-secondary-darker!important;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            height: 100%;
            width: 100%;
            transform-origin: center top;
            transition: transform .4s;
        }

        &:hover{
            &:after{
                transform: scaleY(0);
            }
        }
    }

    .ac_item_container{
        z-index: 2;
    }
    .ac_item_content_icon_container {
        display: block;
        width: 100%;
        svg{
            fill: none;
            transition: all .3s;
            stroke: currentColor;
            width: 100%;
            stroke-width: 1.5;
        }
    }

    .ac_item_icon{
        display: none;
    }
    .ac_item_content{
        background: none!important;
        transition: all .3s;
    }
    .ac_item_content_title {
        font-size: 1.2rem;
        font-weight: 500;
        transition: all .3s;
        color: $brand-secondary-darker;
        text-align: center;
    }
    .ac_item_content_copy {
        position: relative;
        z-index: 2;
        @include breakpoint(medium) {
            padding: $default-padding * 1.5 0;
        }
    }
    // .ac_item_image {
    //     @if $item-list-style-on-small == true {
    //         @include breakpoint(small only) {
    //             opacity: .4;
    //         }
    //     }
    // }

} // end scope