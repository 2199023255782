/* SECTION - DIVIDER - DOTTED
--------------------------------------------------------------------------------------------*/

&[data-s-divider-type="dotted"] {
    .ac_section_divider_top {
        top: -19px;
        position: absolute;
        z-index: 11;
        width: 100%;
    }
    .ac_section_divider_bottom {
        position: absolute;
        z-index: 11;
        width: 100%;
        bottom: -19.53px;
        svg {
            transform: rotate(180deg);
        }
    }
    svg {
        height: 20px;
        width: 100%;
    }
}