
//
//      ICON // e.g. an arrow
//

.ac_item_icon {
    @if $item-show-icon == true {
        
        position: absolute;
        z-index: 2;
        width: $item-icon-size;
        height: $item-icon-size;

        svg {
            width: $item-icon-size;
            height: $item-icon-size;
            fill: $item-icon-color;
            transition: fill $default-transition-speed;
        }
        @if $item-icon-position == 'rt' {
            right: $item-icon-inset-left-right;
            top: $item-icon-inset-top-bottom;
        }
        @if $item-icon-position == 'rb' {
            right: $item-icon-inset-left-right;
            bottom: $item-icon-inset-top-bottom;
        }
        @if $item-icon-position == 'lb' {
            left: $item-icon-inset-left-right;
            bottom: $item-icon-inset-top-bottom;
        }
        @if $item-icon-position == 'lt' {
            left: $item-icon-inset-left-right;
            top: $item-icon-inset-top-bottom;
        }
    } @else {
        display: none;
    }
    
}
