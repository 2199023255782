
/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-1200;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: $default-padding / 4;
$ac-toptasks-items-inner-wrapper-background-color: $brand-lightest-gray;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2;
$ac-toptasks-item-container-padding: 0;
$ac-toptasks-item-has-shadow: false;
$ac-toptasks-item-background-color: $brand-secondary;
$ac-toptasks-item-background-hover-color: $brand-secondary-darker;
$ac-toptasks-item-border-radius: $default-border-radius; 

// image
$ac-toptasks-item-has-image-container: true; 
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: false;
$ac-toptasks-item-has-image: true;
$ac-toptasks-item-has-image-initial-fade: .1;
$ac-toptasks-item-has-image-hover-fade: .5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 110px; // px of em
$ac-toptasks-item-icon-padding: $default-padding * 2; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: 0; 
$ac-toptasks-item-icon-bg: $default-background-light;
$ac-toptasks-item-icon-hover-bg: inherit; // inherit
$ac-toptasks-item-icon-fill: $brand-white;
$ac-toptasks-item-icon-hover-fill: $brand-white;

// title
$ac-toptasks-item-title-color: $brand-white;
$ac-toptasks-item-title-color-hover: $brand-white;

// description
$ac-toptasks-item-description-enabled: true;
$ac-toptasks-item-description-color: $default-text-color;
$ac-toptasks-item-description-color-hover: $brand-white;
$ac-toptasks-item-description-margin-bottom: 1em;

// btn-container
$ac-toptasks-item-btn-container-enabled: true;
$ac-toptasks-item-btn-container-color: $default-text-color;
$ac-toptasks-item-btn-container-color-hover: rgba($default-text-color, .9);

@import '__toptasks_globals';

/*--------------------------------------------------------------------------------------------*/


.ac_toptasks_item_container {
    display: flex;
    align-items: center;
}
.ac_toptasks_item_icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.ac_toptasks_item_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding: $default-padding / 2;
    padding-left: $default-padding;
}

.ac_toptasks_item_content_description{
    display: none;
}

.ac_toptasks_item_content_btn-container .button{
    background: none;
    padding: 0;
}

