
//
//      ITEM HOVER STATES
//


a.ac_item {
    &:hover {
        @include breakpoint(large) {
            @if $item-image-enabled == true {
                .ac_item_image {
                    transform: scale(1.02);
                    opacity: $item-image-hover-fade;
                }
            }
            @if  $item-image-container-enabled == true {
                .ac_item_image-container{
                    background: $item-image-background-color-hover;
                }
            }
            .ac_item_content {
                @if $item-content-background-hover != none or $item-content-background-hover != 0 {
                    background: $item-content-background-hover;
                }
            }
            @if $item-content-icon-enabled == true {
                .ac_item_content_icon {
                    background: $item-content-icon-background-hover;
                    svg {
                        @if $svg-icon-set-type == 'fill' {
                            fill: $item-content-icon-hover-color;
                        } @elseif $svg-icon-set-type == 'stroke' {
                            stroke: $item-content-icon-hover-color;
                            fill: transparent;
                        }
                    }
                }
            }
            @if $item-content-title-enabled == true {
                .ac_item_content_title {
                    color: $item-content-title-hover-color;
                }
            }
            @if $item-content-copy-above-enabled == true {
                .ac_item_content_copy-above {
                    color: $item-content-copy-above-hover-color;
                }
            }
            @if $item-content-copy-below-enabled == true {
                .ac_item_content_copy-below {
                    color: $item-content-copy-below-hover-color;
                }
            }
            @if $item-content-icon-enabled == true {
                .ac_item_icon {
                    svg {
                        fill: $item-icon-hover-color;
                    }
                }
            }
        }
    } 
}