@if $items-columns-enabled == true {


    .ac_grid_container{
        max-width: $items-max-width;
        position: relative;
        z-index: 2;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: $default-padding / 2;

        @if $items-columns-icon-image-combination == true {
            .ac_item_image {
                display: none;
            }
        }
    }

    //
    //      OUTER COLUMN
    //

    .ac_grid_outer-column {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;

        // first outer column
        &:nth-child(1) {
            @media screen and (min-width: $items-columns-breakpoint) {
                width: percentage(3/5);
                max-width: percentage(3/5);
            }
        }
        // second outer column
        &:nth-child(2) {

            @if $items-columns-hide-last-one-below-breakpoint == true {
                @media screen and (max-width: $items-columns-breakpoint - 1) {
                    display: none;
                }
            }
            
            .ac_item  {
                height: $items-columns-height / 2;
                margin-bottom: $item-margin-bottom;
                @media screen and (min-width: $items-columns-breakpoint) {
                    height: ($items-columns-height / 2) - $item-margin-bottom;
                    margin-bottom: inherit;
                }
            }
        
            @media screen and (min-width: $items-columns-breakpoint) {
                width: percentage(2/5);
                max-width: percentage(2/5);
            }

            .ac_item {
                width: percentage(1/2);
                max-width: percentage(1/2);
                margin-bottom: $item-margin-bottom;

                // select items that have an image if icon-image-combination is true
                &:nth-child(1),
                &:nth-child(4) {
                    @if $items-columns-icon-image-combination == true {
                        .ac_item_image { 
                            display: block;
                        }
                    }
                }
            }
        }

    }


    //
    //      COLUMN
    //


    .ac_grid_column {
        height: $items-columns-height;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .ac_item {
            width: 100%;
            height: calc(100% - #{$item-margin-bottom});
        }

        //first column
        &:nth-child(1) {
            height: $items-columns-height / 2;
            .ac_item {
                width: percentage(1/2);
                max-width: percentage(1/2);
                @if $items-columns-icon-image-combination == true {
                    &:nth-child(1){
                        .ac_item_image { 
                            display: block;
                        }
                    }
                }
            }
            @media screen and (min-width: $items-columns-breakpoint) {
                height: $items-columns-height;
                width: percentage(1/3);
                max-width: percentage(1/3);
                .ac_item {
                    width: 100%;
                    max-width: 100%;
                    height: ($items-columns-height / 2) - $item-margin-bottom;
                }
            }
        }
        //second column
        &:nth-child(2) {
            @media screen and (max-width: $items-columns-breakpoint - 1) {
                height: $items-columns-height * 0.7;
            }
            @media screen and (min-width: $items-columns-breakpoint) {
                width: percentage(2/3);
                max-width: percentage(2/3);
            }
            @if $items-columns-icon-image-combination == true {
                .ac_item_image { 
                    display: block;
                }
            }
        }
    
    }


}