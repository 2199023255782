
/* AC_S_TOPTASKS_FIXED - 1
--------------------------------------------------------------------------------------------*/

// container
$ac-toptasks-fixed-background-color: $default-background-light;
$ac-toptasks-fixed-z-index: 3333;
$ac-toptasks-fixed-position: 'rt'; // lt, lb, rt, rb
$ac-toptasks-fixed-border: none;
$ac-toptasks-fixed-border-radius: $default-border-radius;
$ac-toptasks-fixed-position-offset: 25vh; // vh unit measurement. Calculated from top or from bottom.
$ac-toptasks-fixed-width: 400px;

// item
$ac-toptasks-fixed-item-margin-bottom: 0; // 10px
$ac-toptasks-fixed-item-border-radius: $default-border-radius;
$ac-toptasks-fixed-item-background-color: $default-background-light;
$ac-toptasks-fixed-item-background-hover-color: $default-background-light;

// title
$ac-toptasks-fixed-item-title-color: $default-text-color;
$ac-toptasks-fixed-item-title-hover-color: $default-text-color;

// btn-container
$ac-toptasks-fixed-item-btn-container-color: rgba($default-text-color, .5);
$ac-toptasks-fixed-item-btn-container-hover-color: rgba($default-text-color, 1);

// icon
$ac-toptasks-fixed-item-icon-fill: $default-icon-color;
$ac-toptasks-fixed-item-icon-hover-fill: $brand-primary-darker;
$ac-toptasks-fixed-item-icon-size: 30px; // 20px
$ac-toptasks-fixed-item-icon-padding: 15px; // 20px

// for mobile
$ac-toptasks-mobile-background: $default-background-light;

// import globals
@import '__toptasks-fixed_globals';