

@mixin content-align($alignment) {
  
    @if $alignment == 'lt' {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @elseif $alignment == 'lc' {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    @elseif $alignment == 'lb' {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
    @elseif $alignment == 'ct' {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }
    @elseif $alignment == 'cc' {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    @elseif $alignment == 'cb' {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
    }
    @elseif $alignment == 'rt' {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        text-align: right;
    }
    @elseif $alignment == 'rc' {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        text-align: right;
    }
    @elseif $alignment == 'rb' {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
    }
    @else {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}