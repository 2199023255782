
/* LISTBUTTONS - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="listbuttons"] {

    a {
        text-decoration: underline;
        color: $brand-primary !important;

        &:hover {
            color: $brand-primary-darker !important;
        }
    }
    .ac_listbuttons_container {
        padding: 0 $default-padding;
    }

    .ac_listbuttons_container_inner {
        max-width: 400px;
        display: flex;
        flex-wrap: wrap;

        .button {
            width: 100%;
            justify-content: flex-end;
            @include breakpoint(medium) {
                width: 50%;
            }
        }
    }
    
} // end of scope
