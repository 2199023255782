
/* AC_S_IMAGE-GALLERY-GRID - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-image-gallery-grid-items-max-width: $max-content-1200;
$ac-image-gallery-grid-items-have-padding: true;
$ac-image-gallery-grid-items-column-breakpoint: 800px; 
$ac-image-gallery-grid-items-column-height: 400px; 
$ac-image-gallery-grid-items-border-radius: $default-border-radius / 2; 

// item
$ac-image-gallery-grid-item-background: $default-background-dark;
$ac-image-gallery-grid-item-margin-bottom: 20px; // use same measure unit as column specs 
$ac-image-gallery-grid-item-content-padding: $default-padding; 
$ac-image-gallery-grid-item-content-align: 'cc'; // lt, lc, lb, ct, cc, cb, rt, rc, rb
$ac-image-gallery-grid-item-border: $default-border; // defines the appearance, not the display
$ac-image-gallery-grid-item-has-shadow: false;

// image
$ac-image-gallery-grid-item-image-size: 'cover'; // contain or cover. The HTML element cannot have a a_cover_.. class.
$ac-image-gallery-grid-item-image-initial-opacity: 1;
$ac-image-gallery-grid-item-image-hover-opacity: .5;

// description
$ac-image-gallery-grid-item-description-enabled: true;
$ac-image-gallery-grid-item-description-color: $default-text-color-light;
$ac-image-gallery-grid-item-description-word-break: false;

@import '__image-gallery_grid_globals';

/*--------------------------------------------------------------------------------------------*/


.ac_image-gallery_grid_container {
    position: relative;
    z-index: 22;
}

 @include breakpoint(medium) {
    .ac_image-gallery_grid_outer-column {
        &:nth-child(2) {
            padding-top: $ac-image-gallery-grid-items-column-height / 4;
        }
    }

    .ac_image-gallery_grid_container {
        &:nth-child(even) {
            .ac_image-gallery_grid_outer-column {
                &:nth-child(2) {
                    padding-top: initial;
                    margin-top: -$ac-image-gallery-grid-items-column-height / 4;
                }
            }
        }
    }
}