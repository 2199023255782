
/* AC_S_IMAGE-GALLERY - GLOBALS
--------------------------------------------------------------------------------------------*/

.ac_image-gallery_container {
    display: flex;
    flex-wrap: wrap;
    max-width: $ac-image-gallery-items-max-width;
    margin: 0 auto;
}

//
//      ITEM
//

.ac_image-gallery_item {
    padding: $ac-image-gallery-item-padding;
    margin-bottom: $ac-image-gallery-item-margin-bottom;
    @if $ac-image-gallery-items-on-small-two-items-a-row == true {
        flex: 0 1 percentage(1/2);
        max-width: percentage(1/2);
    } @else {
        flex: 0 1 percentage(1);
        max-width: percentage(1);
    }
}
a.ac_image-gallery_item { 
    &:hover {

        .ac_image-gallery_item_container {
            background: $ac-image-gallery-item-hover-background;
        }
        .ac_image-gallery_item_image {
            opacity: $ac-image-gallery-item-image-hover-opacity;
        }
        .ac_image-gallery_item_content_inner { 
            color: $ac-image-gallery-item-description-hover-color;
        }
    }
}

.ac_image-gallery_item_container {
    position: relative;
    background: $ac-image-gallery-item-background;
    height: 100%;
    transition: all $default-transition-speed;
    border-radius: $ac-image-gallery-item-border-radius;
}


//
//      IMAGE
//

.ac_image-gallery_item_image_container {
    position: relative;
    width: 100%;
    height: $ac-image-gallery-item-image-height;
    display: flex;
    justify-content: center;
    @if $ac-image-gallery-item-image-background != none {
        background: $ac-image-gallery-item-image-background;
    }
    @if $ac-image-gallery-item-border-radius != 0 {
        border-radius: $ac-image-gallery-item-border-radius;
    }
}
.ac_image-gallery_item_image {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: $ac-image-gallery-item-image-initial-opacity;
    transition: all $default-transition-speed;
    border-radius: $ac-image-gallery-item-border-radius;

    @if $ac-image-gallery-item-image-size == 'contain'{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center !important;
    } @else {
        background-size: cover;
    }
}


//
//      CONTENT
//

.ac_image-gallery_item_content {
    position: relative;
    padding: $default-padding;
    border-top: $default-border;
    @if $ac-image-gallery-item-description-enabled == false {
        display: none;
    }
}
.ac_image-gallery_item_content_inner {
    transition: opacity $default-transition-speed;
    font-size: 90%;
    color: $ac-image-gallery-item-description-color;
    @include breakpoint(medium) {
        font-size: 100%;
    }
}
