
/* FILTER - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="filter"] {

    .button {
        &.active {
            background: $white !important;
            color: $brand-primary !important;
            border: solid 1px $brand-primary;
        }
    }
} // end of scope


