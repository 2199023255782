
/* FILTER - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="section-content"] {

   a:not(.button) {
       text-decoration: underline;
   }
   
} // end of scope


