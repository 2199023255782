/* SECTION - DIVIDER - DOTTED
--------------------------------------------------------------------------------------------*/

&[data-s-divider-type="semi-transparent"] {


    .ac_section_divider_top {
       
        position: absolute;
        z-index: 11;
        width: 100%;

        svg {
            position: absolute;
            width: 100%;
        }

        &.p_polygon-1 {
            top: -3.9vw;
            z-index: 11;
            svg {
                height: 4vw;
            }
        }
        &.p_polygon-2 {
            top: -7vw;
            z-index: 10;
            opacity: .3;
            svg {
                height: 7vw;
            }
        }
    }
}