
/* PARTNERS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-partners-items-slider-fouc-height: 100px;
$ac-partners-items-have-padding: true;
$ac-partners-items-have-border-dividers: false;
$ac-partners-items-on-small-two-items-a-row: true; // if true two items per row on small, else one item

// item
$ac-partners-item-margin-bottom: 0; // 1.4em
$ac-partners-item-container-padding: 0;
$ac-partners-item-content-padding: $default-padding; 
$ac-partners-item-content-background: none;
$ac-partners-item-border: $default-border; // defines the appearance, not the display
$ac-partners-item-shadow: false; 

// description
$ac-partners-items-description-enable: false;
$ac-partners-items-description-color: rgba($default-text-color-dark,.6);
$ac-partners-items-description-hover-color: $default-text-color-dark;

// logo
$ac-partners-item-logo-margin-bottom: 0; // 1.4em
$ac-partners-item-logo-height: 100px; 
$ac-partners-item-logo-max-width: 140px;
$ac-partners-item-logo-initial-opacity: 1;
$ac-partners-item-logo-hover-opacity: .6;


@import '__partners_globals';

/*--------------------------------------------------------------------------------------------*/

[data-s-type="partners"] {
    .slick-track{
        display: flex;       
    }
}