/* SECTION - DIVIDER - ANGLED SVG
--------------------------------------------------------------------------------------------*/

&[data-s-divider-type="butt-cheeks"] {

    //reduce to make smaller, increase to make larger
    $divider-butt-cheeks-height: 50px;

    .ac_section_divider_top {
        top: -$divider-butt-cheeks-height;
        position: absolute;
        z-index: 11;
        width: 100%;
        display: flex;
    }

    .ac_section_divider_bottom {
        bottom: -$divider-butt-cheeks-height;
        position: absolute;
        z-index: 11;
        width: 100%;
        display: flex;
        transform: rotate(180deg);
    }

    .ac_section_divider_cheek_left {
        width: 50%;
        height:  $divider-butt-cheeks-height;
        border-top-right-radius: $divider-butt-cheeks-height;
    }   
    .ac_section_divider_cheek_right {
        width: 50%;
        height:  $divider-butt-cheeks-height;
        border-top-left-radius: $divider-butt-cheeks-height;
    }   
}