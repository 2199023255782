/* PAGE CONTROL
--------------------------------------------------------------------------------------------*/
body.page-id-179 {
   [data-s-type="hero-alt"]{
     padding-top: 0!important;
     padding-bottom: 0!important;
     background: $brand-secondary!important;
   }
}

body.page-id-177 {
  [data-s-type="hero-alt"]{
    padding-top: 0!important;
    padding-bottom: 0!important;
    background-color: $brand-secondary;
  }
  [data-s-type="section-content"]{
    padding-top: 40px;
    padding-bottom: 40px;
  }
}