
/* AC_S_TEAM - 01 - GLOBALS
--------------------------------------------------------------------------------------------*/


//
//      TEAM SECTION
//


[data-s-type="team"] {

    position: relative;
    padding: $default-padding 0;
    
    @include breakpoint(medium) {
        padding: 0 0 $default-padding / 2 0;
    }   
}

//
//      CONTAINER
//


.ac_team_container{
    max-width: $ac-team-items-max-width;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;

    @if $ac-team-items-have-border-dividers == false and $ac-team-items-have-padding == true {
        padding: 0 $default-padding / 2;
    }
}



//
//      ITEM
//

.ac_team_item {
    width: percentage(1/1); 
    max-width: percentage(1/1);
    margin-bottom: $ac-team-item-margin-bottom;
    text-align: $ac-team-item-text-align;
    display: flex;


    @if $ac-team-items-have-padding == true {
        padding: 0 $default-padding;
    } @else {
        padding: 0;
    }

    @if $ac-team-items-have-border-dividers == true {
        @if $ac-team-items-have-padding == true {
            padding: 0 $default-padding;
        }
        border-left: $ac-team-item-border;
        
    } @else {
        @if $ac-team-items-have-padding == true {
            padding: 0 $default-padding / 2;
        }
    }   

    
    &:hover {
        @include breakpoint(large) {
            .ac_team_item_logo {
                opacity: 1;
            }
        }
    } 
}

.ac_team_item_container {
    width: 100%;
    position: relative;
    background: $ac-team-item-background;
    padding: $ac-team-item-padding;
    @if $ac-team-item-border-radius != 0 {
        border-radius: $ac-team-item-border-radius;
    }
    @if $ac-team-item-shadow == true {
        box-shadow: $default-box-shadow;
    }
    @if $ac-team-items-have-border-below == true {
        border-bottom: $ac-team-item-border;
    }
}


//
//      BORDER RESPONSIVENESS
//


[data-s-type="team"] {
    @if $ac-team-items-have-border-dividers == true {
        @include border-responsiveness('S1');
        @include border-responsiveness('S1-MP2');
        @include border-responsiveness('S1-MP2-M3');
        @include border-responsiveness('S1-MP2-L4');
    }
}

@if $ac-team-items-have-border-below == true and $ac-team-items-hide-border-on-last-row == true {
    [data-s-type="team"] {
        @include bottom-items-no-border('S1-MP2');
        @include bottom-items-no-border('S1-MP2-L3');
        @include bottom-items-no-border('S1-MP2-L4');
    }
}

//
//      CONTENT
//


.ac_team_item_content{
    background: $ac-team-item-content-background;
    padding: $ac-team-item-content-padding;
}



//
//      PROFILE IMAGE
//

.ac_team_item_profile-image_container {
    @if $ac-team-item-profile-image-enabled == false {
        display: none;
    } @else {
        display: flex;
        justify-content: center;
    }
}
.ac_team_item_profile-image {
    @if $ac-team-item-profile-image-enabled == true {
        width: $ac-team-item-profile-image-size;
        height: $ac-team-item-profile-image-size;
        background-size: cover;
        border: $ac-team-item-profile-image-border;
        @if $ac-team-item-profile-image-shadow == true {
            box-shadow: $default-box-shadow;
        }
        @if $ac-team-item-profile-image-rounded == true {
            border-radius: 100%;
        }
    }
}


//
//      NAME
//

.ac_team_item_name {
    font-size: 110%;
    margin-bottom: .6em;
}



//
//      JOB DESCRIPTION
//

.ac_team_item_job-description {
    text-transform: uppercase;
    font-size: 90%;
    margin-bottom: .6em;

    @if $ac-team-item-show-job-description == false {
        display: none;
    }
}


//
//      TELEPHONE
//

.ac_team_item_telephone {
    @if $ac-team-item-show-telephone == false {
        display: none;
    }
}


//
//      EMAIL
//

.ac_team_item_email {
    @if $ac-team-item-show-email == false {
        display: none;
    }
}


//
//      SOCIALS
//

.ac_team_item_socials {
    display: flex;
    margin-top: .6em;
    
    @if $ac-team-item-text-align == center {
        justify-content: center;
    }
    @if $ac-team-item-show-socials == false {
        display: none;
    }
    svg {
        width: $ac-team-item-social-size - $ac-team-item-social-padding * 2;
        height: $ac-team-item-social-size - $ac-team-item-social-padding * 2;
        fill: $ac-team-item-social-icon-fill;
    }
    a {
        margin-right: .6em;
        background: $ac-team-item-social-background;
        width: $ac-team-item-social-size;
        height: $ac-team-item-social-size;
        padding: $ac-team-item-social-padding;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all $default-transition-speed;
        &:last-child() {
            margin-right: 0;
        }
        @if $ac-team-item-social-rounded == true{
            border-radius: 100%;
        }
        @include breakpoint(large) {
            &:hover {
                background: $ac-team-item-social-background-hover;
                svg {
                    fill: $ac-team-item-social-icon-hover-fill;
                }
            }
        }
    }
}
