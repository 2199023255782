
/* AC_S_HERO - GLOBALS
--------------------------------------------------------------------------------------------*/

//
//      CONTAINER
//

.ac_hero_alt_container {
    margin: 0 auto;
    height: $hero-alt-height / 2;
    min-height: $hero-alt-min-height; // optional for smaller sizes, to prevent collapsing of content
    position: relative;
    display: block;
    @include breakpoint(medium) {
        height: $hero-alt-height;
    }
}


//
//      SLIDES
//

.ac_hero_alt_slides {
    position: relative;
    z-index: 3;
    height: 100%;
    display: block;
    margin: 0 auto;

    //slick fuoc fix
    max-height: 100%;
    overflow: hidden; // overflow hidden stays intact for hero slider. This also allows for fading content in after fuoc fix.
    opacity: 0;
    transition: opacity $default-transition-speed;
    
    // drop fouc if slick is initialized
    &.slick-initialized  {
        max-height: inherit;
        opacity: 1;
    }

}
.ac_hero_alt_slide {
    will-change: contents;
    position: relative;
}
.ac_hero_alt_slide_inner {
    max-width: $hero-alt-slide-max-width;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    will-change: contents;
    padding: 0 $default-padding;
    @if $hero-alt-slide-arrows-enabled == true {
        @media screen and (min-width: $hero-alt-slide-arrows-enabled-from) {
            padding: 0 $default-padding * 4;
        }
    } 
}
.ac_hero_alt_slide_content_container {
    @include breakpoint(medium) {
        transform: translateY($hero-alt-slide-content-correction);
    }
    will-change: contents;
}

.ac_hero_alt_slide_content {
    display: flex;
}
.ac_hero_alt_slide_content_inner {
    display: flex;
    align-items: center;
    width: 100%;
    @if $hero-alt-slide-align == 'center' {
        justify-content: center;
    }
}

.ac_hero_alt_slide_content_text {
    flex: 0 1 percentage(1);
    max-width: percentage(1);
    @include breakpoint(medium-portrait) {
        flex: 0 1 percentage($hero-alt-slide-content-text-width);
        max-width: percentage($hero-alt-slide-content-text-width);
    }
    @if $hero-alt-slide-type == 'text' {
        @if $hero-alt-slide-align == 'center' {
            text-align: center;
        }
    }

}
.ac_hero_alt_slide_content_image {
    display: flex;
    justify-content: center;
    @if $hero-alt-slide-type == 'text' {
        display: none;
    }
    @if $hero-alt-slide-type == 'text-image' {
        flex: 0 1 100% - percentage($hero-alt-slide-content-text-width);
        max-width: 100% - percentage($hero-alt-slide-content-text-width);

        @if $hero-alt-slide-image-on-small == 'none'{
            @include breakpoint(small only) {
                display: none; // image is not shown on small
            }
        }
        @if $hero-alt-slide-image-on-small == 'absolute'{
            @include breakpoint(small only) {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: -1;
                opacity: .5;
            }            
        }
    }
}



//
//      SLICK FIXES
//

    .slick-list {
        height: 100%;
    }
    .slick-track {
        height: 100%;
    }
    .slick-prev,
    .slick-next  {
        display: none !important; // overrule the Slick js
        background: none;
        padding: 0;
        box-shadow: none;

        @if $hero-alt-slide-arrows-enabled == true {
            width: $hero-alt-slide-arrows-size-small;
            height: $hero-alt-slide-arrows-size-small;
            @media screen and (min-width: $hero-alt-slide-increase-arrows-size-from) {
                display: block !important; 
            }
            @media screen and (min-width: $hero-alt-slide-increase-arrows-size-from) {
                width: $hero-alt-slide-arrows-size-large;
                height: $hero-alt-slide-arrows-size-large;
            }
            svg {
                fill: white;
                width: $hero-alt-slide-arrows-size-small;
                height: $hero-alt-slide-arrows-size-small;
                @media screen and (min-width: $hero-alt-slide-increase-arrows-size-from) {
                    width: $hero-alt-slide-arrows-size-large;
                    height: $hero-alt-slide-arrows-size-large;
                }
            }
        }
    }
    @if $hero-alt-slide-arrows-enabled == true {

        @if $hero-alt-slide-arrows-position == 'left-and-right-centered' {
            .slick-prev {
                transform: translateY(-50%) translateX($hero-alt-slide-arrows-size-small * 2);
                @media screen and (min-width: $hero-alt-slide-increase-arrows-size-from) {
                    transform: translateY(-50%) translateX($hero-alt-slide-arrows-size-large * 2);
                }
                @include breakpoint(xlarge) {
                    transform: translateY(-50%) translateX($hero-alt-slide-arrows-size-large * 4);
                }
                @include breakpoint(xxlarge) {
                    transform: translateY(-50%) translateX($hero-alt-slide-arrows-size-large * 6);
                }
            }   
            .slick-next  {
                transform: translateY(-50%) translateX(-$hero-alt-slide-arrows-size-small * 2);
                @media screen and (min-width: $hero-alt-slide-increase-arrows-size-from) {
                    transform: translateY(-50%) translateX(-$hero-alt-slide-arrows-size-large * 2);
                }
                @include breakpoint(xlarge) {
                    transform: translateY(-50%) translateX(-$hero-alt-slide-arrows-size-large * 4);
                }
                @include breakpoint(xxlarge) {
                    transform: translateY(-50%) translateX(-$hero-alt-slide-arrows-size-large * 6);
                }
            }
        }

        @if $hero-alt-slide-arrows-position == 'bottom-centered' {
            .slick-prev {
                top: initial;
                bottom: $hero-alt-slide-arrows-bottom-position;
                left: 50%;
                transform: translateX($hero-alt-slide-arrows-size-small * 6);
            }   
            .slick-next  {
                top: initial;
                bottom: $hero-alt-slide-arrows-bottom-position;
                right: 50%;
                transform: translateX(-$hero-alt-slide-arrows-size-small * 6);
            }
        }
    }
    
    .slick-dots {
        bottom: $hero-alt-slide-arrows-bottom-position;
    }


//
//      BACKDROP
//

.ac_hero_alt_backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}
.ac_hero_alt_backdrop_image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.ac_hero_alt_backdrop_image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: $hero-alt-backdrop-till-medium-fade;
    mix-blend-mode: $hero-alt-backdrop-till-medium-blend-mode;
    @if $hero-alt-backdrop-till-medium-grayscale == true {
        filter: grayscale(100%);
    }
    @include breakpoint(medium) {
        opacity: 1;
        mix-blend-mode: normal;
        filter: grayscale(0%);
    }
}

.ac_hero_alt_backdrop_video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
    }
}


//
//      LINK TO CONTENT
//

.ac_hero_alt_link_to-content {
    @if $hero-alt-link-to-content-enabled == true {        
        position: absolute;
        z-index: 1111;
        bottom: $hero-alt-link-to-content-bottom;
        left: 50%;
        transform: translateX(-50%);

        @if $hero-alt-link-to-content-show-till-medium == false {
            display: none;
            @include breakpoint(medium) {
                display: block;
            }
        }

        @include breakpoint(large) {
            &:hover {
                cursor: pointer;
            }
        }
        .svg-container {
            width: 2em;
            height: 2em;
        }
        svg {
            width: 2em;
            height: 2em;
        }
        &:active {
            transform: translateX(-50%) translateY(2px);
        }
    } @else {
        display: none;
    }
}