/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
[data-s-id="home-toptasks"] {
    position: relative;
    z-index: 3;
    padding-top: $default-padding * 2;
}

[data-s-id="home-services"] {
    .ac_item_content {
        background: $brand-white;
    }
}