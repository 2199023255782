//
//      CONTAINER
//

.ac_container {
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;

    @if $items-have-border-dividers == false and $items-have-padding == true {
        padding: 0 $default-padding / 2;
    }
}