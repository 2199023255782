
/* IMAGE - GLOBALS
--------------------------------------------------------------------------------------------*/

// render defaults within mixin to avoid sass error of not calling a selector
@mixin defaults() {
    position: relative;
    background: $brand-background-primary-reversed;
}
@include defaults();

.ac_image {
    position: absolute;
    width: 100%;
    height: 100%;
    
    figure {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .2;
        background-attachment: fixed;
    }
}

