/* GENERICS - ANCHORS
--------------------------------------------------------------------------------------------*/
a { 
    // //disable tel links on large
    // &[href^="tel"] {
    //     @include breakpoint(large) {
    //         pointer-events: none; // dis
    //     }
    // }
}


//default anchors in content
.ac_content {
    a:not(.button) {
        color: $brand-primary;

        &:hover {
            color: $brand-primary-darker !important;
        }
    }
}