/* SECTION - DIVIDER - SPIKED
--------------------------------------------------------------------------------------------*/

&[data-s-divider-type="spiked"] {
    .ac_section_divider_top {
        top: -19px;
        position: absolute;
        z-index: 11;
        width: 100%;
    }
    .ac_section_divider_bottom {
        position: absolute;
        z-index: 11;
        width: 100%;
        bottom: -25px;
        svg {
            transform: rotate(180deg);
        }
    }
    svg {
        height: 20px;
        width: 100%;
    }
}