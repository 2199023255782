/* SECTION - DIVIDER - ANGLED SVG
--------------------------------------------------------------------------------------------*/

&[data-s-divider-type="curved-cheeks"] {

    //reduce to make smaller, increase to make larger
    $angled-divider-height: 10vw;

    .ac_section_divider_top {
        top: -$angled-divider-height;
        position: absolute;
        z-index: 11;
        width: 100%;

        svg {
            position: absolute;
            width: 100%;
            height: $angled-divider-height;
            transform: translateY(1px); // prevent possible line in safari
        }
    }

    .ac_section_divider_bottom {
        bottom: -$angled-divider-height;
        position: absolute;
        z-index: 11;
        width: 100%;
        transform: rotate(180deg);

        svg {
            position: absolute;
            width: 100%;
            height: $angled-divider-height;
            transform: translateY(1px); // prevent possible line in safari
        }
    }
}