//
//      IMAGE
//

.ac_item_image-container{
    @if $item-image-container-enabled == true{
        height: $item-image-height;
        width: 100%;
        overflow: hidden;
        position: relative;
        transition: all $default-transition-speed;
        @if $item-image-background-color != none or $item-image-background-color != 0 {
            background: $item-image-background-color;
        }
        @if $item-image-border-radius != 0 {
            border-radius: $item-image-border-radius;
        }
    } @else {
        display: none;
    }
}
.ac_item_image {
    @if $item-image-enabled == true {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: $item-image-initial-fade;
        transition: all $default-transition-speed;
        will-change: contents;
        @if $item-image-border-radius != 0 {
            border-radius: $item-image-border-radius;
        }
    } @else {
        display: none;
    }
}
