/* MIXIN - BORDER RESPONSIVENESS
----------------------------------------------------------------------------------------------------

    This mixin calculates the tranparent borders on grids, so you have borders between items, but not on the left side. 
    It accepts a pattern, (S1-MP2), which means on small 1 item per row, on medium-portrait 2, etc. 
    The first item can be set as large and therefore 100% width of parent container size.

*/


@mixin border-responsiveness($pattern, $first-item-large: false) {

    //
    //      S1 versions
    //

    @if($pattern == 'S1'){ 
        &[data-s-amount="1"] {
            [data-s-amount-item] { 
                border-left: transparent;
            }
        }
    }

    @if($pattern == 'S1-MP2'){ 
        @if $first-item-large == true {
            &[data-s-amount="2"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                            border-left: transparent;
                    }
                    @include breakpoint(medium-portrait) {
                        &:nth-child(1),
                        &:nth-child(2n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="2"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                            border-left: transparent;
                    }
                    @include breakpoint(medium-portrait) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }
    
    @if($pattern == 'S1-MP2-M3'){ 
        @if $first-item-large == true {
            &[data-s-amount="3"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                         border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="3"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                            border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium) {
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S1-MP2-M3-L4'){ 
        @if $first-item-large == true {
            &[data-s-amount="4"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                         border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(4n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="4"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                            border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium only) {
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(4n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S1-MP2-L4'){ 
        @if $first-item-large == true {
            &[data-s-amount="4"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                         border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(4n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="4"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                            border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium only) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(4n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }


    @if($pattern == 'S1-MP2-L5'){ 
        @if $first-item-large == true {
            &[data-s-amount="5"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                         border-left: transparent;
                    }
                    @include breakpoint(medium-portrait) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(5n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="5"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                            border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(5n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }
    
    //
    //      S2 versions
    //
    
    @if($pattern == 'S2' or $pattern == 'S2-MP2'){ 
        @if $first-item-large == true {
            &[data-s-amount="2"] {
                [data-s-amount-item] { 
                    &:nth-child(1),
                    &:nth-child(2n+2) {
                        border-left: transparent;
                    }
                }
            }
        } @else {
            &[data-s-amount="2"] {
                [data-s-amount-item] { 
                    &:nth-child(2n+1) {
                        border-left: transparent;
                    }
                }
            }
        } 
    }

    @if($pattern == 'S2-M3' or $pattern == 'S2-MP2-M3'){  
        @if $first-item-large == true {
            &[data-s-amount="3"] {
                [data-s-amount-item] { 
                    @include breakpoint(medium-portrait down) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(2n+2) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="3"] {
                [data-s-amount-item] { 
                    @include breakpoint(medium-portrait down) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium) {
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S2-L4' or $pattern == 'S2-MP2-L4'){ 
        @if $first-item-large == true {
            &[data-s-amount="4"] {
                [data-s-amount-item] { 
                    @include breakpoint(medium down) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(2n+2) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(4n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="4"] {
                [data-s-amount-item] { 
                    @include breakpoint(medium down) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(4n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }

    @if($pattern == 'S2-L5'){ 
        @if $first-item-large == true {
            &[data-s-amount="5"] {
                [data-s-amount-item] { 
                    @include breakpoint(small only) {
                         border-left: transparent;
                    }
                    @include breakpoint(medium-portrait only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(medium only) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(5n+2) {
                            border-left: transparent;
                        }
                    }
                }
            }
        } @else {
            &[data-s-amount="5"] {
                [data-s-amount-item] {
                    @include breakpoint(medium down) {
                        &:nth-child(2n+1) {
                            border-left: transparent;
                        }
                    }
                    @include breakpoint(large) {
                        &:nth-child(5n+1) {
                            border-left: transparent;
                        }
                    }
                }
            }
        }
    }
    

}