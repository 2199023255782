/* LIST SEARCH
--------------------------------------------------------------------------------------------*/

.ac_list-search {
    list-style: none;
    margin: 0;
    border-top: $default-border;
}
.ac_list-search_item{
    border-bottom: $default-border;
}
.ac_list-search_item_link{
    padding: 2em 0;
    display: block;
    text-decoration: none !important;
}
.ac_list-search_item_content {

}
.ac_list-search_item_title {
    
}
