/* GENERICS - SLICK SLIDER
--------------------------------------------------------------------------------------------*/

.slick-dots {
    position: absolute;
    z-index: 222;
    left: 50%;
    bottom: -2em;
    list-style: none;
    display: flex;
    margin: 0;
    transform: translateX(-50%);

    @include breakpoint(medium) {
        bottom: -4em;
    }

    button {
        border: solid 1px rgba($brand-white, .2); 
        border: none;
        background: $brand-light-gray; 
        width: 1em;
        height: 1em;
        border-radius: 50%;
        margin: 0 .15em;
        text-indent: -99999px;
        box-shadow: 0 0 4px rgba($brand-black, .1);

        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
        
    }

    .slick-active {
        button {
            // background: $brand-white; 
        }   
    }
}

.slick-slide {
    &:focus {
        outline: none;
    }
}

button.slick-prev,
button.slick-next {
    position: absolute;
    z-index: 222;
    top: 50%;
    transform: translateY(-50%);
    width: 3em;
    height: 3em;
    padding: .5em;
    background: transparent;
    // border-radius: $default-border-radius / 2;
    // box-shadow: $default-box-shadow;
    svg {
        width: 2em;
        height: 2em;
        fill: $default-icon-color;
    }
    &:hover {
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
}
button.slick-prev {
    left: 0;
}
button.slick-next {
    right: 0;
}