
/* AC_S_TOPTASKS - GLOBALS
--------------------------------------------------------------------------------------------*/


//
//      TOPTASKS FIXED SECTION
//


[data-s-type="toptasks-fixed"] {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $ac-toptasks-fixed-z-index;
    width: 100%;
    background: $ac-toptasks-fixed-background-color;
    border: $ac-toptasks-fixed-border;

    @include breakpoint(medium down) { 
        box-shadow: -5px 0 10px rgba(black, .1);
    }
    @include breakpoint(large) { 
    
        box-shadow: $default-box-shadow;

        @if $ac-toptasks-fixed-position == 'rt' {
            top: $ac-toptasks-fixed-position-offset;
            right: 0;
            bottom: inherit;
            left: inherit;
        }
        @if $ac-toptasks-fixed-position == 'rb' {
            bottom: $ac-toptasks-fixed-position-offset;
            right: 0;
            top: inherit;
            left: inherit;
        }
        @if $ac-toptasks-fixed-position == 'lt' {
            top: $ac-toptasks-fixed-position-offset;
            left: 0;
            bottom: inherit;
            right: inherit;
        }
        @if $ac-toptasks-fixed-position == 'lb' {
            bottom: $ac-toptasks-fixed-position-offset;
            left: 0;
            top: inherit;
            right: inherit;
        }

        width: $ac-toptasks-fixed-width;
        border-radius: $ac-toptasks-fixed-border-radius;
        @if $ac-toptasks-fixed-position == 'rt' or $ac-toptasks-fixed-position == 'rb' {
            transform: translateX(#{$ac-toptasks-fixed-width - ($ac-toptasks-fixed-item-icon-size + ($ac-toptasks-fixed-item-icon-padding * 2))});
        } @else {
            transform: translateX(-#{$ac-toptasks-fixed-width - ($ac-toptasks-fixed-item-icon-size + ($ac-toptasks-fixed-item-icon-padding * 2))});
        }

    }

}


//
//      CONTAINER
//

.ac_toptasks_fixed_container_inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    @include breakpoint(medium-portrait) { 
        padding: 0;
    }
    @include breakpoint(large) { 
        display: block;
    }
}

.ac_toptasks_fixed_item_container{
    display: flex;
    align-items: center;
    justify-content: center;
    @if $ac-toptasks-fixed-position == 'lt' or $ac-toptasks-fixed-position == 'lb' {
        flex-direction: row-reverse;
    }
    @include breakpoint(small only) { 
        flex-direction: column;
    }
    @include breakpoint(large) { 
        justify-content: flex-start;
    }

}


//
//      ITEM
//

.ac_toptasks_fixed_item {
    display: block;
    background: $ac-toptasks-fixed-background-color;
    border-radius: $ac-toptasks-fixed-item-border-radius;
    width: $ac-toptasks-fixed-width - 2px;
    position: relative;

    @include breakpoint(medium down) { 
        &:after {
            content: '';
            position: absolute;
            top: 10%;
            right: 0;
            background: $ac-toptasks-mobile-background;
            width: 1px;
            height: 80%;
        }
        &:last-child() {
            &:after {
                display: none;
            }
        }
    }
        


    @include breakpoint(large) { 
        margin-bottom: $ac-toptasks-fixed-item-margin-bottom;
        transition: transform $default-transition-speed;
        will-change: contents;
        &:hover {
            background: $ac-toptasks-fixed-item-background-hover-color;
            @if $ac-toptasks-fixed-position == 'rt' or $ac-toptasks-fixed-position == 'rb' {
                transform: translateX(-200px);
            } @else {
                transform: translateX(200px);
            }
            .ac_toptasks_fixed_item_icon {
                svg {
                    fill: $ac-toptasks-fixed-item-icon-hover-fill;
                }
            }
            .ac_toptasks_fixed_item_content_title {
                color: $ac-toptasks-fixed-item-title-hover-color;
            }
            .ac_toptasks_fixed_item_content_btn-container {
                color: $ac-toptasks-fixed-item-btn-container-hover-color;
            }
        }
    }
}


//
//      CONTENT
//

.ac_toptasks_fixed_item_content {
    @if $ac-toptasks-fixed-position == 'lt' or $ac-toptasks-fixed-position == 'lb' {
        text-align: right;
    }
}
.ac_toptasks_fixed_item_content_title {
    font-size: 90%;
    color: $ac-toptasks-fixed-item-title-color;
    @include breakpoint(large) {
        display: none;
    }
    @include breakpoint(small only) {
        text-align: center;
    }
}
.ac_toptasks_fixed_item_content_btn-container {
    white-space: nowrap;
    display: none;
    color: $ac-toptasks-fixed-item-btn-container-color;
    @include breakpoint(large) {
        display: inline-block;
        width: 200px;
    }
}


//
//      ICON
//

.ac_toptasks_fixed_item_icon-container {
    position: relative;
    z-index: 1;
}
.ac_toptasks_fixed_item_icon {
    width: $ac-toptasks-fixed-item-icon-size * .7;
    height: $ac-toptasks-fixed-item-icon-size * .7;
    background: inherit;
    padding: 0;
    border-radius: 100%;

    @include breakpoint(medium-portrait) {
        width: $ac-toptasks-fixed-item-icon-size + ($ac-toptasks-fixed-item-icon-padding * 2);
        height: $ac-toptasks-fixed-item-icon-size + ($ac-toptasks-fixed-item-icon-padding * 2);
        padding: $ac-toptasks-fixed-item-icon-padding;
    }   

    svg {
        width: $ac-toptasks-fixed-item-icon-size * .7;
        height: $ac-toptasks-fixed-item-icon-size * .7;
        fill: $ac-toptasks-fixed-item-icon-fill;
        @include breakpoint(medium-portrait) {
            width: $ac-toptasks-fixed-item-icon-size;
            height: $ac-toptasks-fixed-item-icon-size;
        }
    }
}

