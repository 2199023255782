
/* AC_S_IMAGE-GALLERY - GLOBALS
--------------------------------------------------------------------------------------------*/
[data-s-type="image-gallery-grid"] {
    .ac_image-gallery_grid_container {
        &:nth-child(even) {
            flex-direction: row-reverse;
            .ac_image-gallery_grid_outer-column {
                &:nth-child(1) {
                    flex-direction: row-reverse;
                }
                &:nth-child(2) {
                    .ac_image-gallery_grid_column {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}


.ac_image-gallery_grid_container {
    display: flex;
    flex-wrap: wrap;
    max-width: $ac-image-gallery-grid-items-max-width;
    margin: 0 auto;
}


//
//      OUTER COLUMN
//

.ac_image-gallery_grid_outer-column {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    // first outer column
    &:nth-child(1) {
        @media screen and (min-width: $ac-image-gallery-grid-items-column-breakpoint) {
            width: percentage(3/5);
            max-width: percentage(3/5);
        }

        //first inner column of first outer column
        .ac_image-gallery_grid_column {
            &:nth-child(1) {
                height: $ac-image-gallery-grid-items-column-height / 2;
                .ac_image-gallery_grid_item {
                    width: percentage(1/2);
                    max-width: percentage(1/2);
                    @media screen and (min-width: $ac-image-gallery-grid-items-column-breakpoint) {
                        max-height: 50%;
                    }
                }
                @media screen and (min-width: $ac-image-gallery-grid-items-column-breakpoint) {
                    height: $ac-image-gallery-grid-items-column-height;
                    width: percentage(1/3);
                    max-width: percentage(1/3);
                    .ac_image-gallery_grid_item {
                        width: 100%;
                        max-width: 100%;
                        height: ($ac-image-gallery-grid-items-column-height / 2) - $ac-image-gallery-grid-item-margin-bottom ;
                    }
                }
            }
            //second column
            &:nth-child(2) {
                @media screen and (max-width: $ac-image-gallery-grid-items-column-breakpoint - 1) {
                    height: $ac-image-gallery-grid-items-column-height * 0.7;
                }
                @media screen and (min-width: $ac-image-gallery-grid-items-column-breakpoint) {
                    width: percentage(2/3);
                    max-width: percentage(2/3);
                }
            }
        }
    }


    // second outer column
    &:nth-child(2) {
        @media screen and (min-width: $ac-image-gallery-grid-items-column-breakpoint) {
            width: percentage(2/5);
            max-width: percentage(2/5);
        }

        .ac_image-gallery_grid_item {
            width: percentage(1/2);
            max-width: percentage(1/2);
        }
    }

}


//
//      COLUMN
//


.ac_image-gallery_grid_column {
    height: $ac-image-gallery-grid-items-column-height;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}



//
//      ITEM
//


.ac_image-gallery_grid_item {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    margin-bottom: $ac-image-gallery-grid-item-margin-bottom;

    @if $ac-image-gallery-grid-items-have-padding == true {
        padding: 0 $default-padding;
    } @else {
        padding: 0;
    }

    @if $ac-image-gallery-grid-items-have-padding == true {
        padding: 0 $default-padding / 2;
    }
    

    &:hover {
        @include breakpoint(large) {
            .ac_image-gallery_grid_item_image {
                transform: scale(1.02);
                opacity: $ac-image-gallery-grid-item-image-hover-opacity;
            }
            .ac_image-gallery_grid_item_content {
                opacity: 1;
                visibility: visible;
            }
        }
    } 
}

.ac_image-gallery_grid_item_container {
    height: 100%;
    // width: 100%;
    position: relative;
    flex-grow: 1;
    @if $ac-image-gallery-grid-item-has-shadow == true {
        box-shadow: $default-box-shadow;
    }
    background: $ac-image-gallery-grid-item-background;
    border-radius: $ac-image-gallery-grid-items-border-radius;
    overflow: hidden; // optional
}



//
//      IMAGE
//

.ac_image-gallery_grid_item_image-container{
    height: 160px;
    width: 100%;
    border-radius: $ac-image-gallery-grid-items-border-radius;
    position: relative;
    overflow: hidden;
    
}
.ac_image-gallery_grid_item_image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: $ac-image-gallery-grid-item-image-initial-opacity;
    border-radius: $ac-image-gallery-grid-items-border-radius;
    transition: all $default-transition-speed;
    will-change: contents;
    @if $ac-image-gallery-grid-item-image-size == 'contain' {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
}


//
//      CONTENT
//

.ac_image-gallery_grid_item_content{ 
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: $ac-image-gallery-grid-item-content-padding;
}



//
//      CONTENT OVER IMAGE
//


.ac_image-gallery_grid_item_image-container {
    border-radius: $ac-image-gallery-grid-items-border-radius;
    height: 100%;
    position: absolute;    
}
.ac_image-gallery_grid_item_image {

}
.ac_image-gallery_grid_item_content {

    @if $ac-image-gallery-grid-item-description-enabled == false{
        display: none;
    } @else {
        position: absolute;
        z-index: 2;
        top: 0;
        border: none;
        height: 100%;
        width: 100%;
        display: flex;
        opacity: 0;
        visibility: hidden;
        left: 0;
        @if $ac-image-gallery-grid-item-description-word-break == true {
            word-break: break-all;
        }
        color: $ac-image-gallery-grid-item-description-color;

        @include content-align($ac-image-gallery-grid-item-content-align);
    }

}


