
/* SECTION - DIVIDER 
--------------------------------------------------------------------------------------------*/


$ac_section_divider: true; //enable or disable all section dividers


.ac_section_divider {

    pointer-events: none; // prevent interference with ui elements

    @if $ac_section_divider == false {
        display: none;
    }

    // @import 'ac_section_divider/angled';
    @import 'ac_section_divider/angled-svg';
    @import 'ac_section_divider/butt-cheeks';
    @import 'ac_section_divider/curved-cheeks';
    @import 'ac_section_divider/dotted';
    @import 'ac_section_divider/semi-transparent';
    @import 'ac_section_divider/smile';
    @import 'ac_section_divider/spiked';
    @import 'ac_section_divider/spiked-small';

}

