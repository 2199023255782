
/* IMAGE - 1
--------------------------------------------------------------------------------------------*/


[data-s-type="image"] {


    @import '__image_globals';

    /*--------------------------------------------------------------------------------------------*/

    // set height for large
    @include breakpoint(large) {
        min-height: 300px;
    }

    
} // end of scope

