
/* CTA
--------------------------------------------------------------------------------------------*/


[data-s-type="maps"] {
    
    .ac_maps_container {
        width: 100%;
        height: 400px;

    }
    
    /*--------------------------------------------------------------------------------------------*/


} // end of scope


