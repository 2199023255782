
/* AC_S_TEAM - 1
--------------------------------------------------------------------------------------------*/

//items
$ac-team-items-max-width: $max-content-800;
$ac-team-items-have-padding: true;
$ac-team-items-have-border-dividers: false;
$ac-team-items-have-border-below: false;
$ac-team-items-hide-border-on-last-row: false;

//item
$ac-team-item-margin-bottom: 1.4em; // 1.4em
$ac-team-item-border: $default-border; // defines the appearance, not the display
$ac-team-item-text-align: center; // left or center
$ac-team-item-padding: $default-padding 0;
$ac-team-item-background: $default-background-light;
$ac-team-item-border-radius: $default-border-radius;
$ac-team-item-shadow: true;

//content
$ac-team-item-content-padding: $default-padding; 
$ac-team-item-content-background: transparent; 

//profile image
$ac-team-item-profile-image-enabled: true;
$ac-team-item-profile-image-rounded: true;
$ac-team-item-profile-image-border: solid 6px $brand-white;
$ac-team-item-profile-image-size: 120px; 
$ac-team-item-profile-image-shadow: true;

//content copy
$ac-team-item-show-job-description: true;
$ac-team-item-show-telephone: true;
$ac-team-item-show-email: true;

//socials
$ac-team-item-show-socials: true;
$ac-team-item-social-background: $default-background-dark;
$ac-team-item-social-background-hover: $default-background-dark;
$ac-team-item-social-rounded: true;
$ac-team-item-social-icon-fill: $default-icon-color-light;
$ac-team-item-social-icon-hover-fill: rgba($default-icon-color-light, .5);
$ac-team-item-social-size: 40px; // use the same measurment unit for size and padding!
$ac-team-item-social-padding: 5px;

$ac-team-item-logo-margin-bottom: .6em; // 1.4em

@import '__team_globals';

/*--------------------------------------------------------------------------------------------*/