
/* AC_S_NEWS - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="news"] {

    // items
    $items-slider-fouc-height: 200px; // what needs to be the flash-of-unwanted-content height before slider has laoded.

    $items-amount: 0; // 0 counts for undefined, if assigned the code will be more optimised
    $items-content-over-image: false;
    $items-have-padding: true;
    $items-have-border-dividers: true;
    $items-have-border-below: true;
    $items-hide-border-below-on-last-row: true;
    $items-border-radius: 0; 
    $items-first-item-full-width: false;
    $items-first-item-full-width-height-multiplier: 1.5;
    $items-on-small-two-items-a-row: true;
    $item-background: none;
    $item-background-hover: none;

    // columns
    $items-columns-enabled: false;
    $items-columns-breakpoint: 800px; 
    $items-columns-height: 400px; 
    $items-columns-hide-last-one-below-breakpoint: false;
    $items-columns-icon-image-combination: true;

    // item
    $item-margin-bottom: 0; // 1.4em
    $item-border: $default-border; // defines the appearance, not the display
    $item-shadow-enabled: false;

    // image

    $item-image-container-enabled: true;
    $item-image-enabled: true;
    $item-image-height: 160px;
    $item-image-initial-fade: 1;
    $item-image-hover-fade: .8;
    $item-image-gradient-before: true; // when item-content-over-image
    $item-image-gradient-color: $default-background-dark;
    $item-image-gradient-before-position: 'bottom'; // top or bottom
    $item-image-background-color: $default-background-dark;
    $item-image-background-color-hover: $default-background-dark;
    $item-image-border-radius: $default-border-radius; 


    // content
    $item-content-padding: 0; // $default-padding; 
    $item-content-background: none;
    $item-content-background-hover: none;
    $item-content-align: 'lb'; // lt, lc, lb, ct, cc, cb, rt, rc, rb ||  when item-content-over-image
    $item-content-copy-padding: $default-padding 0 ; 
    $item-content-copy-align: 'left'; // left or center

    $item-content-icon-enabled: false;
    $item-content-icon-centered: true;
    $item-content-icon-color: rgba($default-icon-color-dark, 1);
    $item-content-icon-hover-color: rgba($default-icon-color-light, .4);
    $item-content-icon-size: 80px;
    $item-content-icon-padding: 20px;
    $item-content-icon-margin: 0 0 20px 0;
    $item-content-icon-background: none;
    $item-content-icon-background-hover: none;
    $item-content-icon-round: true;
    
    $item-content-title-enabled: true; 
    $item-content-title-color: $brand-primary;
    $item-content-title-hover-color: rgba($brand-primary, .6);

    $item-content-copy-above-enabled: true;    
    $item-content-copy-above-color: rgba($brand-primary, .6);
    $item-content-copy-above-hover-color: rgba($brand-primary, .4);

    $item-content-copy-below-enabled: true;    
    $item-content-copy-below-color: rgba($brand-primary, .6);
    $item-content-copy-below-hover-color: rgba($brand-primary, .4);

    // icon
    $item-show-icon: true;
    $item-icon-color: rgba($default-icon-color-dark, 1);
    $item-icon-hover-color: rgba($default-icon-color-dark, 1);
    $item-icon-size: 1.6em;
    $item-icon-position: 'rb'; // lt, lb, rt, rb
    $item-icon-inset-left-right: 0; // $default-padding; 
    $item-icon-inset-top-bottom: $default-padding; 
    

    // list style on small | image left and content right | all background will be reset | content icon is set absolute
    $item-list-style-on-small: true; 
    $item-list-style-image-width: 80px;
    $item-list-style-content-icon-size: 60px; 
    $item-list-style-content-icon-padding: 14px; 
    $item-list-style-content-icon-background: $default-background-dark;
    $item-list-style-content-icon-color:  $default-icon-color-light;
    $item-list-style-content-title-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-above-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-below-color: rgba($default-text-color, 1);


    @import '../ac_s__items-globals/__item_globals';
    

    /*--------------------------------------------------------------------------------------------*/
    
    .ac_item {
        flex-direction: column;
        padding: $default-padding $default-padding 0 $default-padding!important;
        transition: all .3s;
        @include breakpoint(large) { 
            &:hover {
                background: $brand-lightest-gray;
                .ac_item_content_title {
                    color: $brand-primary !important;
                }
            }
        }
    }

    .ac_item_image-container{
        display: none;
    }
    
    .ac_item .ac_item_content_copy { 
        @include breakpoint(small only) {
            padding: 0;
        }
    }
    .ac_item_content_copy-above {
        text-transform: uppercase;
        font-size: 85%;
        letter-spacing: 1px;
        color: $item-content-copy-above-color !important; // don't know why, but on small it needs this
    }
    .ac_item_content_title {
        padding: 10px 0;
        @include font-headings-bold;
        font-size: 110%;
        @include breakpoint(medium-portrait) { 
            min-height: 100px;
            padding-bottom: $default-padding * 2;
        }
        
    }
    .ac_item_content_copy-below {
        text-transform: uppercase;
        font-size: 85%;
        letter-spacing: 1px;
        @include breakpoint(medium-portrait) { 
            position: absolute;
            bottom: $default-padding;
        }
    }

} // end scope