/* GENERICS - FORM
--------------------------------------------------------------------------------------------*/
input,
textarea,
select {
    
    border-color: rgba($brand-black, .3) !important;
    border-radius: 0 !important;
    padding: 0rem 2rem 0rem 1rem !important;
    height: 3rem !important;

    &:disabled {
        background-color: transparent;
        border-color: rgba($brand-black, .15) !important;
        color: $brand-black !important;
        opacity: 1 !important;
    }
    &::placeholder {
       font-style: italic;
       color: rgba($brand-black, .6) !important;
    }

    &:focus {
        border-color: rgba($brand-primary, 1) !important;
    }
}

select {
    padding: 0rem 2.5rem 0 1rem !important;
    background-position: right -18px center;
}
textarea {
    min-height: 100px;
    padding: 1rem !important;
}

input[type="radio"] {
    height: auto !important;
}
input[type="checkbox"] {
    height: auto !important;
}
input[type="submit"],
input[type="button"]{
     padding: 1rem 1rem 1rem 1rem !important;
    //  border: solid 3px $brand-light-gray !important;
}
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label {
    font-size: 0.9rem;
}






.gfield_label {
    font-size: 85%;
}
.gfield_radio {
    display: flex;
    li {
        margin-right: 1em !important;
        margin-bottom: 0 !important;
        display: flex;
    }
}

.gform_wrapper label.gfield_label {
    font-weight: 400 !important;
}

.gform_button {
    color: $brand-white;
    border-radius: $default-border-radius !important;
    line-height: 1 !important;
}

.validation_error,
.validation_message {
    display: none;
}

// .gfield_radio {
//     list-style: none;
//     margin: 0;
// }
// .gfield_checkbox {
//     list-style: none;
//     margin: 0;
// }
// .gform_wrapper {

// }
// .gform_fields  {
//     list-style: none;
//     margin: 0;
// }

// .gfield_error {
//     input {
//         border-color: $brand-error !important;
//     }
// }

// .validation_message {
//     display: none;
// }
// .validation_error {
//     display: none;
// }
