
/* COOKIE MESSAGE - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="cookie-message"] {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 6666;
    padding: $default-padding 0;
    background: $brand-white;
    box-shadow: 0 0 10px rgba(black, .2);
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: transform $default-transition-speed;
} // end of scope

body {
    &.s_is-open_cookie-message {
        [data-s-type="cookie-message"] {
            opacity: 1;
            pointer-events: initial;
            transform: translateY(0);
        }
    }
}

