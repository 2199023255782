
/* BREADCRUMBS - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="breadcrumbs"] {
    border-bottom: $default-border;
    padding: 15px 0;


} // end of scope

p#breadcrumbs {
    margin: 0;
    color: $brand-gray;
    transform: translateX(-.5em);
    a,
    span.breadcrumb_last {
        text-transform: uppercase;
        font-size: 70%;
        letter-spacing: 1px;
        margin-right: 1em;
        padding-left: 1em;
    }

}
