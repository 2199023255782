//
//      CONTENT OVER IMAGE
//
@mixin content-over-image() {
    .ac_item {
        height: $item-image-height;
        flex-direction: column;
        
        @include breakpoint(medium-portrait) {
            height: $item-image-height;
        }

            @if $items-first-item-full-width == true {
            &:nth-child(1) {
                height: $item-image-height * $items-first-item-full-width-height-multiplier;
            }
        }
    }

    .ac_item_image-container {
        @if $items-border-radius != 0 {
            border-radius: $items-border-radius;
        }
        height: 100%;
        background: $item-image-background-color;
        position: absolute;


        @if $item-image-gradient-before == true {
            
            $gradient-degrees: 0deg;
            
            @if $item-image-gradient-before-position == 'bottom' {
                $gradient-degrees: 180deg;
            }

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient($gradient-degrees, rgba($item-image-gradient-color, 0), rgba($item-image-gradient-color, 1));
                z-index: 1;
                @if $items-border-radius != 0 {
                    border-radius: $items-border-radius;
                }
                @if $item-image-gradient-before-position == 'bottom' {
                    bottom: 0;
                }
            }
        }

    }

    .ac_item_content {
        position: absolute;
        z-index: 2;
        top: 0;
        border: none;
        height: 100%;
        width: 100%;
        display: inline-flex;
        left: 0;
        // word-break: break-all;
        @include content-align($item-content-align);

    }
    .ac_item_container {
        overflow: hidden;
    }
}


@if $items-content-over-image == true {

    @include content-over-image();

}