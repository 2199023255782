
/* IMAGE - 1
--------------------------------------------------------------------------------------------*/


[data-s-type="image-bg-fixed"] {

    @import '__image-bg-fixed_globals';

    /*--------------------------------------------------------------------------------------------*/

    // set height for large
    @include breakpoint(large) {
        height: 300px;
    }

    
} // end of scope

