
/* VAC - 2
--------------------------------------------------------------------------------------------*/

$ac-vac-items-slider: false;
$ac-vac-items-slider-fouc-height: 500px; // what needs to be the flash-of-unwanted-content height before slider has laoded.
$ac-vac-items-breakpoint: large;
$ac-vac-items-max-width: $max-content-1200;

$ac-vac-item-visual-width: 1*.7;  // can be any percentage calculation, eg 1*.8 or 1/4. The content will take the space left. Make sure the content has enough space. 
$ac-vac-item-visual-min-height: 500px; // on small the visual height will be set as 50% of the min-height
$ac-vac-item-content-inner-max-width: 500px;
$ac-vac-item-content-inner-min-width: 500px; // set to 100% if the space of the content left is smaller than the set pixels you would prefer. 
$ac-vac-item-margin-bottom: 1.4em;
$ac-vac-item-has-border-bottom: false;
$ac-vac-item-border: $default-border;


@import '__vac_globals';


/*--------------------------------------------------------------------------------------------*/


$max-visual-width-percentage: 60%; // at what percentage point should the SASS force 50% medium devices.

.ac_vac_item {
    &.v_text-right {
        @include breakpoint(medium-portrait) {
            .ac_vac_item_content_inner {
                transform: translateX(-20%);
            }
        }
    }
}

.ac_vac_item_visual {
    @if percentage($ac-vac-item-visual-width) >= $max-visual-width-percentage {
        @include breakpoint(medium-portrait only) {
            width: 50%;
            max-width: 50%;
        }
        @include breakpoint(medium only) {
            width: 50%;
            max-width: 50%;
        }
    }
}
.ac_vac_item_content {
        @if percentage($ac-vac-item-visual-width) >= $max-visual-width-percentage {
            @include breakpoint(medium-portrait only) {
                width: 50%;
                max-width: 50%;
            }
            @include breakpoint(medium only) {
                width: 50%;
                max-width: 50%;
            }
        }
}

.ac_vac_item_content_inner {
    width: 90%;
    min-width: initial;
    margin: 0 auto;
    background: $brand-white;
    box-shadow: 10px 10px 20px rgba($brand-black, .2);
    z-index: 2;
    position: relative;
    padding: $default-padding * 2;
    transform: translateY(-2em);
    @include breakpoint(medium-portrait) {
        transform: translateX(20%);
        max-width: $ac-vac-item-content-inner-max-width;
        margin: $default-padding * 2 auto;
    }
}
